import { NgModule } from "@angular/core";
import { Routes, RouterModule, ROUTES } from "@angular/router";
import { AuthGuard } from "./services/authGuard/auth.guard";
import { InitComponent } from "./pages/init/init.component";
import { SiginCallbackComponent } from "./pages/signin-callback/signin-callback.component";
import { ForgotPasswordComponent } from "./pages/forgot-password/forgot-password/forgot-password.component";
import { RenewtokenComponent } from "./pages/renewtoken/renewtoken.component";
import { SelfSamplingInstructionComponent } from "./pages/self-sampling-instruction/self-sampling-instruction.component";
import { ParentSelfSamplingInstructionComponent } from "./pages/parent-self-sampling-instruction/parent-self-sampling-instruction.component";
import { GeneralTermsComponent } from "./pages/general-terms/general-terms.component";
import { DeleteAccountComponent } from "./pages/delete-account/delete-account.component";
import { ManagedTermsOfUseComponent } from "./pages/terms-of-use/managed-terms-of-use/managed-terms-of-use.component";
import { ManagedPrivacyPolicyComponent } from "./pages/privacy-policy/managed-privacy-policy/managed-privacy-policy.component";
import { InitSessionComponent } from "./pages/init-session/init-session.component";
import { SendForgotPasswordComponent } from "./pages/send-forgot-password/send-forgot-password.component";
import { CheckLogin } from "./services/authGuard/check.login.guard";
import { WasRedirectComponent } from "./pages/was-redirect/was-redirect.component";
import { EePrivacyTermsComponent } from "./pages/ee-privacy-terms/ee-privacy-terms.component";
import { Config, ConfigService } from "./services/config/config.service";
import { claimTypes, claimValues, whiteLabelNames } from "./appsettings";
import { ActivateAccountComponent } from "./pages/activate-account/activate-account.component";
import { PreventShfyGuard } from "./services/guards/prevent-shfy.guard";
import { UserTypeGuard } from "./services/guards/user-type.guard";
import { AgreementType } from "./services/agreement/agreement.service";
import { MedioRedirectComponent } from "./component-modules/medio-redirect/medio-redirect.component";
import { RedirectGuard } from "./guards/redirect.guard";

function routesFactory(config: ConfigService) {
  const routes: Routes = [
    {
      path: '404',
      loadChildren: () => import('./pages/not-found-page/not-found-page.module').then(m => m.NotFoundPageModule)
    },
    {
      path: "general-terms",
      component: GeneralTermsComponent,
      pathMatch: "full",
    },
    {
      path: "privacy-policy",
      canActivate: [CheckLogin],
      component: ManagedPrivacyPolicyComponent,
      data: { page: AgreementType.PrivacyPolicy },
      pathMatch: "full",
    },
    {
      path: "additional-information-about-collected-data",
      canActivate: [CheckLogin],
      component: ManagedPrivacyPolicyComponent,
      data: { page: AgreementType.ADDITIONAL_INFO_COLLECTED_DATA },
      pathMatch: "full",
    },
    {
      path: "terms-of-use",
      canActivate: [CheckLogin],
      component: ManagedTermsOfUseComponent,
      pathMatch: "full",
    },
    {
      path: "terms-of-use-mobile",
      component: ManagedTermsOfUseComponent,
      pathMatch: "full",
    },
    {
      path: "self-sampling-instruction",
      component: SelfSamplingInstructionComponent,
      pathMatch: "full",
    },
    {
      path: "parent-self-sampling-instruction",
      component: ParentSelfSamplingInstructionComponent,
      pathMatch: "full",
    },
    {
      path: '',
      outlet: 'fi-cookie-module',
      loadChildren: () => import('./view/fi-cookie-manager/fi-cookie-manager.module').then(m => m.FiCookieManagerModule)
    },
    {
      path: "",
      component: InitComponent,
    },
    {
      path: "signin-callback",
      component: SiginCallbackComponent,
    },
    {
      path: "renewtoken",
      component: RenewtokenComponent,
    },
    {
      path: "init-session",
      component: InitSessionComponent,
    },
    {
      path: "was",
      component: WasRedirectComponent,
    },
    {
      path: "privacyterms",
      component: EePrivacyTermsComponent,
    },
    {
      path: "WAS",
      redirectTo: "was",
    },
    {
      path: "account",
      canActivate: [AuthGuard, UserTypeGuard, PreventShfyGuard],
      loadChildren: () => import("./pages/index/index.module").then((m) => m.IndexModule)
    },
    {
      path: "account/v3",
      canActivate: [AuthGuard, UserTypeGuard, PreventShfyGuard],
      loadChildren: () => import("./view/profile-v3/profile-v3.module").then((m) => m.ProfileV3Module)
    },
    {
      path: "forgot_password",
      component: ForgotPasswordComponent,
    },
    {
      path: "send_forgot_password",
      component: SendForgotPasswordComponent,
    },
    {
      path: "activate_account",
      component: ActivateAccountComponent,
    },
    {
      path: "delete_account",
      component: DeleteAccountComponent,
    },
    {
      path: "complete_your_profile",
      redirectTo: "registration",
      pathMatch: "full",
    },
    {
      path: "registration",
      canActivate: [PreventShfyGuard],
      loadChildren: () => {
        if (canProceedToNewUI(config._envConfig)) {
          return import("./view/registerv2/registerv2.module").then( (m) => m.Registerv2Module );
        }
        return import("./pages/profile/profile.module").then( (m) => m.ProfileModule );
      },
    },
    {
      path: "registration",
      loadChildren: () =>
        import("./view/tara-register/tara-register.module").then(
          (m) => m.TaraRegisterModule
        ),
    },
    {
      path: "mfa",
      loadChildren: () =>
        import("./view/dk-mfa-reset/mfa-reset.module").then(
          (m) => m.MfaResetModule
        )
    },
    {
      path: "medio",
      component: MedioRedirectComponent,
      canActivate: [RedirectGuard, AuthGuard]
    },
    {
      path: "**",
      redirectTo: "",
      pathMatch: "full",
    },
  ];
  return routes;
}

function getQueryParameter(key: string): string {
  const parameters = new URLSearchParams(window.location.search);
  return parameters.get(key);
}

function canProceedToNewUI(config: Config) {
  const isTaraActivation = getQueryParameter("isTara")?.toLowerCase() == claimValues.TRUE
  const useNewUI = (config['UseNewUI' as keyof Config] as string).toLowerCase() == claimValues.TRUE;
  return useNewUI && !isTaraActivation;
}

@NgModule({
  imports: [RouterModule.forRoot([], { relativeLinkResolution: "legacy" })],
  providers: [
    {
      provide: ROUTES,
      useFactory: routesFactory,
      multi: true,
      deps: [ConfigService],
    },
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
